/* .techstack__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
  background-color: #171825;
  padding: 0px 30px 0px 30px;
} */

/* .techstack__left {
    min-height: 120px;
  width: 100%;
  background-color: #212231;
  border-radius: 62px;

  box-shadow: 0px -4px 15px -3px rgba(0,0,0,0.1),0px 10px 23px 0px rgba(0,0,0,0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 10px;
} */
.techstack__left_logo {
  /* width: 180px; */
  /* width: 10vw; */
  width: 100%;
  margin-top: 20px;
}
.techstack__left_text {
  font-size: 22px;
  padding: 0px 15px 0px 15px;
  /* font-size: 2.5vw; */
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}
.techstack__left_text_Color {
  color: #874dad;
}
/* 
.techstack__right {
    min-height: 100px;
    width: 100%;
  background-color: #212231;
  border-radius: 62px;
  
  box-shadow: 0px -4px 15px -3px rgba(0,0,0,0.1),0px 10px 23px 0px rgba(0,0,0,0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 10px;
} */

.techstack__right_logo {
  /* width: 200px; */
  width: 100%;
  margin-top: 20px;
}
.techstack__right_text {
  font-size: 22px;
  padding: 0px 15px 0px 15px;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}

.techstack__right_text_M {
  color: #49a53c;
}
.techstack__right_text_E {
  color: #8a8484;
}
.techstack__right_text_R {
  color: #61dafb;
}
.techstack__right_text_N {
  color: #8cc84b;
}

.techstack__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 280px;
  background-color: #171825;
  padding: 0px 30px 0px 30px;
  padding: 20px;
}

.techstack__left {
  flex: 1;
  /* border: 1px solid black; */
  /* New */
  background-color: #212231;
  border-radius: 62px;

  box-shadow: 0px -4px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 23px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 10px;
  min-width: 250px;
  margin: 10px;
}
.techstack__right {
  flex: 1;
  /* New */
  background-color: #212231;
  border-radius: 62px;
  box-shadow: 0px -4px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 23px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 10px;
  min-width: 250px;
  margin: 10px;
}

@media (max-width: 600px) {
  .techstack__container {
    flex-direction: column;
  }
}

.techstack__LogoHolder {
    width: 56px;
    display: flex;
    flex-direction: row;
}
.techstack__LogoHolderRight {
    width: 200px;
    display: flex;
    flex-direction: row;
}