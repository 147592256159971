.header__bg {
  background-image: url("../assets/images/AthulBGnew.jpg");
  /* height: 200px; */
  /* min-height: 400px; */
  background-repeat: no-repeat;
  /* background-size: 100%; */
  background-color: black;
  /* padding-top: 70px; */
  display: flex;
  /* padding: 40px; */
  padding: 0 1em;
  /* width: 100%;
  height: auto; */
  background-size: cover;
  /* min-height: 100%; */
  padding-bottom: 100px;
}
.header__left {
  /* display: flex; */
  /* height: 550px; */
  flex-direction: column;
  flex: 0.5;
  padding-left: 20px;
  margin-top: 40px;
  /* padding: 100px 30px 30px 100px; */
}
.header__right {
  /* height: 550px; */
  display: flex;

  flex-direction: row;
  flex: 0.5;
}

.header__line1 {
  font-size: 40px;
  font-weight: 350;
  color: white;
  float: left;
  /* padding-left: 30px; */

  /* height: 0px; */
  /* padding-left: 125px; */
}

.header__hi {
  /* font-size: 40px; */
  font-weight: 350;
  color: white;
  font-size: 5.5vw;
  margin-left: 40px;
  /* line-height: 1px; */
}

.header__line2 {
  /* font-size: 140px; */
  /* font-size:10vw; */
  font-weight: bolder;
  color: white;
  float: right;
  /* display: flex;
  justify-content: space-between; */
}
.header__athul {
  font-size: 10vw;
  /* font-size: 140px; */
  /* padding: 0 1em; */
}

.header__line3 {
  /* font-size: 25px; */
  font-weight: bold;
  color: white;
  float: right;
  /* display: flex;
  justify-content: right; */
  padding-bottom: 20px;
}
.header__line3_creator {
  font-size: 3vw;
}
