.experience__title {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: #171825;
  /* margin-top: 140px; */
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding-top: 40px;
  flex: 1;
  /* width: 500px; */
}

.experience__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 250px;
  background-color: #171825;
}

.experience_left {
  min-height: 164px;
  width: 326px;
  background-color: #212231;
  border-radius: 8px;
  box-shadow: 0px -4px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 23px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  line-height: 0.5px;
  /* flex: 1; */
  margin-right: 10px;
  margin-left: 10px;
}
.experience_left_logo {
  width: 60px;
  height: 60px;
}
.experience_middle {
  min-height: 164px;
  width: 326px;
  background-color: #212231;
  border-radius: 8px;
  box-shadow: 0px -4px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 23px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.experience_right {
  min-height: 164px;
  width: 326px;
  background-color: #212231;
  border-radius: 8px;
  box-shadow: 0px -4px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 23px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: 10px;
}
.experience_right_logo{
    width: 90px;
    padding-top: 20px;
}
.experience_Company_right {
    font-weight: bold;
    color: white;
    font-size: 26px;
    padding-top: 10px;
  }
.experience_Company {
  font-weight: bold;
  color: white;
  font-size: 26px;
}
.experience_year {
  font-weight: bold;
  color: white;
  padding-bottom: 10px;
}

.codeicon {
  width: 50px;
  height: 50px;
  padding-top: 0px;
}
.experience__titleDetail {
  display: flex;
}
.experience__title_text {
  padding-left: 15px;
}
