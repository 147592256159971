@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap");
/* 
.Technologies__Container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 600px;

  
  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/leaves.png");
  background-repeat: repeat;
  background-size: auto;

}

.Technologies__Container_Left {
  min-height: 600px;
  width: 450px;
  flex: 0.7;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/leaves.png");
  background-repeat: repeat;
  background-size: auto;
}

.Technologies__Container_Right {
  min-height: 730px;
  width: 450px;
  flex: 0.3;
  
  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/leaves.png");
  background-repeat: repeat;
  background-size: auto;
}

.Technologies__Container_Left_Frontend {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.Technologies__Container_Left_Backend {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.Technologies__Container_Right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}
.AvatarHolder {
  background-color: #212231;
  width: 270px;
  height: 270px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 160px;
}
.AvatarInnerHolder {
  background-color: #e5e6eb;
  width: 260px;
  height: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 160px;
}
.Technologies__Container_Left_1 {
  font-size: 50px;
  font-weight: 490;
  margin-left: 20px;
  flex: 0.3;
}
.Technologies__Container_Left_2 {
  font-size: 300px;
  font-weight: lighter;
  font-family: "Amatic SC";
  flex: 0.4;
}

.Technologies__Container_Left_3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.Technologies__Container_Left_icon {
  width: 65px;
}
.Technologies__Container_Left_icon2 {
  width: 85px;
}
.Technologies__Container_Left_icon3 {
  width: 100px;
} */

.Technologies__container {
  display: flex;
}

.Technologies__left {
  flex: 1 1;
    border: 1px solid #e2e2e2;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;
    /* padding: 10px; */
    /* margin-right: 20px; */
    border-radius: 20px;
    background-color: #f0f0f0;
    box-shadow: -2px 7px 25px -9px rgba(0, 0, 0, 0.75);
    /* width: 400px; */
    margin-bottom: 15px;
    min-height: 150px;
    margin: 10px;
}
.Technologies__right {
  flex: 1 1;
  border: 1px solid #e2e2e2;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  /* padding: 10px; */
  border-radius: 20px;
  background-color: #f0f0f0;
  box-shadow: -2px 7px 25px -9px rgba(0, 0, 0, 0.75);
  /* width: 400px; */
  margin-bottom: 15px;
  min-height: 150px;
  margin: 10px;

}

@media (max-width: 600px) {
  .Technologies__container {
    flex-direction: column;
  }
}


.Technologies__front{
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}
.Technologies__back{
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}
.Technologies__LogosHolder{
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
  margin-top: 10px;
}
.Technologies__Logo{
  width: 55px;
  padding: 5px;
}
.Logo{
  width: 100%;
}